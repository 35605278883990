import React, {useState} from "react";
import '../sass/news.css';
import CustomH2 from "../components/technicalComponents/CustomH2";
import CustomArticle from "../components/technicalComponents/CustomArticle";
import article1 from '../img/articles/article1.webp';
import article2 from '../img/articles/article2.webp';
import article3 from '../img/articles/article3.webp';
import article4 from '../img/articles/article4.webp';
import article5 from '../img/articles/article5.webp';
import article6 from '../img/articles/article6.webp';
import article7 from '../img/articles/article7.webp';
import article8 from '../img/articles/article8.webp';
import article9 from "../img/articles/article9.webp";
import article11 from "../img/articles/article11.webp";
import article10 from "../img/articles/article10.webp";
import article12 from "../img/articles/article12.webp";
import article13 from "../img/articles/article13.webp";
import article15 from "../img/articles/article15.webp";
import article14 from "../img/articles/article14.webp";
import article16 from "../img/articles/article16.webp";
import article17 from "../img/articles/article17.webp";
import article18 from "../img/articles/article18.webp";
import article19 from "../img/articles/article19.webp";
import article20 from "../img/articles/article20.webp";
import article21 from "../img/articles/article21.webp";
import article22 from "../img/articles/article22.webp";

import {useTranslation} from "react-i18next";
import CustomButton from "../components/technicalComponents/CustomButton";
import article24 from "../img/articles/article24.webp";
import article23 from "../img/articles/article23.webp";
import article26 from "../img/articles/article26.webp";
import article25 from "../img/articles/article25.webp";

const News = () => {
    const [showMore, setShowMore] = useState(false);
    const {t} = useTranslation();
    return(
        <div className="newsContainer">
            <CustomH2 text="News"/>
            <h3>{t("under_title")}</h3>
            <div className="newsContainerGrid">
                <CustomArticle
                    date="2025-03-30"
                    title={t("art_26_title")}
                    image={article26}
                    isLarge={true}
                    linkEN="https://mailchi.mp/a5988411145f/maciej-gadysz-wins-the-spanish-winter-championship?e=9eec90125b"
                    linkPL="https://mailchi.mp/4d59b16870e1/maciej-gadysz-mistrzem-spanish-winter-championship?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-03-27"
                    title={t("art_25_title")}
                    image={article25}
                    isLarge={false}
                    linkEN="https://mailchi.mp/99ff5e0b8e61/maciej-gadysz-to-fight-for-the-title-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/9478d7a49615/maciej-gadysz-powalczy-o-tytu-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-23"
                    title={t("art_24_title")}
                    image={article24}
                    isLarge={false}
                    linkEN="https://mailchi.mp/c446de68a35a/maciej-gadysz-on-the-podium-again?e=9eec90125b"
                    linkPL="https://mailchi.mp/106ae40df9f1/maciej-gadysz-ponownie-na-podium?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-20"
                    title={t("art_23_title")}
                    image={article23}
                    isLarge={false}
                    linkEN="https://mailchi.mp/4f247dd5abff/maciej-gadysz-ready-to-race-again-in-portimo?e=9eec90125b"
                    linkPL="https://mailchi.mp/a3e693432ee6/maciej-gadysz-gotowy-na-wycigi-w-portimao?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-09"
                    title={t("art_22_title")}
                    image={article22}
                    isLarge={false}
                    linkEN="https://mailchi.mp/7b253839a95e/maciej-gadysz-wins-on-his-formula-3-debut?e=9eec90125b"
                    linkPL="https://mailchi.mp/94b08317395b/maciej-gadysz-wygrywa-w-debiucie-w-bolidzie-f3?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-05"
                    title={t("art_21_title")}
                    image={article21}
                    isLarge={false}
                    linkEN="https://mailchi.mp/3b279602d185/maciej-gadysz-to-make-his-debut-in-a-new-category?e=9eec90125b"
                    linkPL="https://mailchi.mp/71d9c333b022/maciej-gadysz-zadebiutuje-w-nowej-kategorii?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-12-17"
                    title={t("art_20_title")}
                    image={article20}
                    isLarge={false}
                    linkEN="https://mailchi.mp/69c0895f0037/gadysz-stays-with-mp-motorsport-for-eurocup-3-graduation?e=9eec90125b"
                    linkPL="https://mailchi.mp/b0c68df27330/maciej-gadysz-awansuje-do-serii-eurocup-3?e=9eec90125b"
                />

                {showMore && (
                    <>
                        <CustomArticle
                            date="2024-11-10"
                            title={t("art_19_title")}
                            image={article19}
                            isLarge={false}
                            linkEN="https://mailchi.mp/94238edc70d8/maciej-gadysz-claims-third-place-in-spanish-formula-4-championship?e=9eec90125b"
                            linkPL="https://mailchi.mp/6448f350a30e/maciej-gadysz-drugim-wicemistrzem-hiszpaskiej-formuy-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-11-07"
                            title={t("art_18_title")}
                            image={article18}
                            isLarge={false}
                            linkEN="https://mailchi.mp/43fa3a283617/maciej-gadysz-ready-for-the-f4-title-showdown?e=9eec90125b"
                            linkPL="https://mailchi.mp/a4e91e2e25c3/maciej-gadysz-gotowy-na-decydujce-starcie-w-formule-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-10-06"
                            title={t("art_17_title")}
                            image={article17}
                            isLarge={false}
                            linkEN="https://mailchi.mp/577a58674629/maciej-gadysz-twice-on-the-podium-in-jerez?e=9eec90125b"
                            linkPL="https://mailchi.mp/22c3932aae54/maciej-gadysz-dwukrotnie-na-podium-w-jerez?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-10-02"
                            title={t("art_16_title")}
                            image={article16}
                            isLarge={false}
                            linkEN="https://mailchi.mp/94a5e8263ef3/maciej-gadysz-ready-for-jerez?e=9eec90125b"
                            linkPL="https://mailchi.mp/e987f6df45a5/maciej-gadysz-gotowy-do-walki-w-jerez?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-09-16"
                            title={t("art_15_title")}
                            image={article15}
                            isLarge={false}
                            linkEN="https://mailchi.mp/ffe2b2871837/maciej-gadysz-on-f4-podium-again-in-valencia?e=9eec90125b"
                            linkPL="https://mailchi.mp/8c28d642a2e7/maciej-gadysz-na-podium-w-walencji?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-09-11"
                            title={t("art_14_title")}
                            image={article14}
                            isLarge={false}
                            linkEN="https://mailchi.mp/b72f12b011b9/maciej-gadysz-back-in-action-in-valencia?e=9eec90125b"
                            linkPL="https://mailchi.mp/fdbc50c86d55/maciej-gadysz-wraca-do-walki-w-walencji?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-07-28"
                            title={t("art_13_title")}
                            image={article13}
                            isLarge={false}
                            linkEN="https://mailchi.mp/0f00add48d4b/double-f4-victory-for-maciej-gadysz-in-aragon?e=9eec90125b"
                            linkPL="https://mailchi.mp/47d1093915d9/podwjne-zwycistwo-macieja-gadysza-w-f4-w-aragonii?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-07-25"
                            title={t("art_12_title")}
                            image={article12}
                            isLarge={false}
                            linkEN="https://mailchi.mp/d8e16e14620a/maciej-gadysz-aiming-high-in-f4-in-aragon?e=9eec90125b"
                            linkPL="https://mailchi.mp/7072457b9cd3/maciej-gadysz-po-kolejne-trofea-w-f4-w-aragonii?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-07-07"
                            title={t("art_11_title")}
                            image={article11}
                            isLarge={false}
                            linkEN="https://mailchi.mp/013a4a04bd09/maciej-gadysz-takes-first-formula-4-victory?e=9eec90125b"
                            linkPL="https://mailchi.mp/d16a597a4b8d/maciej-gadysz-wygrywa-w-formule-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-07-04"
                            title={t("art_10_title")}
                            image={article10}
                            isLarge={false}
                            linkEN="https://mailchi.mp/fb8773827424/maciej-gadysz-ready-to-race-on-another-f1-track?e=9eec90125b"
                            linkPL="https://mailchi.mp/c04b0556e190/maciej-gadysz-gotowy-do-walki-na-kolejnym-torze-f1?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-06-10"
                            title={t("art_09_title")}
                            image={article9}
                            isLarge={false}
                            linkEN="https://mailchi.mp/c52dc040e007/maciej-gadysz-takes-points-in-portimao?e=9eec90125b"
                            linkPL="https://mailchi.mp/951f0111dfc8/widowiskowa-pogo-i-punkty-gadysza-w-portimao?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-05-12"
                            title={t("art_08_title")}
                            image={article8}
                            isLarge={false}
                            linkEN="https://mailchi.mp/983e6652e8cd/maciej-gadysz-on-the-podium-in-spanish-f4-debut?e=9eec90125b"
                            linkPL="https://mailchi.mp/7a308ab7b672/maciej-gadysz-na-podium-w-debiucie-w-hiszpaskiej-f4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-05-09"
                            title={t("art_07_title")}
                            image={article7}
                            isLarge={false}
                            linkEN="https://mailchi.mp/a5c1a5b9f982/maciej-gadysz-starts-the-spanish-f4-season?e=9eec90125b"
                            linkPL="https://mailchi.mp/bff6887be3e5/maciej-gadysz-rozpoczyna-sezon-w-hiszpaskiej-formule-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-04-14"
                            title={t("art_06_title")}
                            image={article6}
                            isLarge={false}
                            linkEN="https://mailchi.mp/d8e4144e58af/maciej-gadysz-to-race-for-poland-in-fia-motorsport-games?e=9eec90125b"
                            linkPL="https://mailchi.mp/f1a9c8ff585c/maciej-gadysz-reprezentantem-polski-na-3-igrzyskach-sportw-motorowych-fia-motorsport-games?e=9eec90125b"
                            isTextSmall={true}
                        />
                        <CustomArticle
                            date="2024-03-11"
                            title={t("art_01_title")}
                            image={article1}
                            isLarge={false}
                            linkEN="https://mailchi.mp/c625e019df11/maciej-gadysz-back-at-the-front-in-formula-12683241?e=9eec90125b"
                            linkPL="https://mailchi.mp/f47e9044d65c/maciej-gadysz-w-czowce-formuy-4-w-aragonii-12683240?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-03-03"
                            title={t("art_02_title")}
                            image={article2}
                            linkPL="https://mailchi.mp/a855544e1319/maciej-gadysz-w-czowce-formuy-4-w-aragonii?e=9eec90125b"
                            linkEN="https://mailchi.mp/715d9e4e64b6/maciej-gadysz-back-at-the-front-in-formula-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-18"
                            title={t("art_03_title")}
                            image={article3}
                            linkPL="https://mailchi.mp/54285bb2dda1/maciej-gadysz-ponownie-w-czowce-formuy-4?e=9eec90125b"
                            linkEN="https://mailchi.mp/c52e2784644d/maciej-gadysz-at-the-front-again-in-formula-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-11"
                            title={t("art_05_title")}
                            image={article5}
                            linkPL="https://mailchi.mp/825919836a0e/wymarzony-debiut-gadysza-w-formule-4?e=9eec90125b"
                            linkEN="https://mailchi.mp/c5b24d1fb5ae/perfect-f4-debut-for-maciej-gadysz?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-07"
                            title={t("art_04_title")}
                            image={article4}
                            linkPL="https://mailchi.mp/c0f8a25386b3/maciej-gadysz-rozpoczyna-sezon-f4-w-jerez?e=9eec90125b"
                            linkEN="https://mailchi.mp/f36107b5628a/maciej-gadysz-ready-for-f4-debut-in-spain?e=9eec90125b"
                        />
                    </>
                )}
            </div>
            <div className="buttonContainer">
                <CustomButton label={showMore ? t("button_03") : t("button_02")} onClick={() => setShowMore(!showMore)}/>
            </div>

        </div>
    );
}

export default News;