import React from "react";
import '../sass/homeNews.css';

import CustomH2 from "./technicalComponents/CustomH2";
import CustomArticle from "./technicalComponents/CustomArticle";

import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import article24 from "../img/articles/article24.webp";
import article25 from "../img/articles/article25.webp";
import article26 from "../img/articles/article26.webp";

const HomeNews = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/news');
    }
    return(
        <section className="newsSection">
            <CustomH2 text="News"/>
            <div className="newsSection-grid">
                <CustomArticle
                    date="2025-03-30"
                    title={t("art_26_title")}
                    image={article26}
                    isLarge={true}
                    linkEN="https://mailchi.mp/a5988411145f/maciej-gadysz-wins-the-spanish-winter-championship?e=9eec90125b"
                    linkPL="https://mailchi.mp/4d59b16870e1/maciej-gadysz-mistrzem-spanish-winter-championship?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-03-27"
                    title={t("art_25_title")}
                    image={article25}
                    isLarge={false}
                    linkEN="https://mailchi.mp/99ff5e0b8e61/maciej-gadysz-to-fight-for-the-title-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/9478d7a49615/maciej-gadysz-powalczy-o-tytu-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-23"
                    title={t("art_24_title")}
                    image={article24}
                    isLarge={false}
                    linkEN="https://mailchi.mp/c446de68a35a/maciej-gadysz-on-the-podium-again?e=9eec90125b"
                    linkPL="https://mailchi.mp/106ae40df9f1/maciej-gadysz-ponownie-na-podium?e=9eec90125b"
                />
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </section>
    );
};

export default HomeNews;